@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.SideBarAgente {
    position: fixed;
    width: 60px;
    height: 100vh;
    background: rgb(5, 161, 233); /* Alterado para a cor azul céu */
    transition: .5s;
    overflow: hidden;
    font-family: Poppins;
    box-shadow: 4px 5px 4px rgba(0,0,0,0.15);
}

.SideBarAgente:hover {
    width: 300px;
}

.SideBarAgente ul li a {
    display: flex;
    white-space: nowrap;
}

.SideBarAgente ul li .icone {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    min-width: 60px;
    height: 60px;
    color: #fff;
}

.SideBarAgente ul li .titulo {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
}

.SideBarAgente ul li:hover {
    background:  rgb(17, 0, 80);
}

.SideBarAgente .logo {
    margin-bottom: 50px;
}

.SideBarAgente .logo:hover {
    background: none;
}

.SideBarAgente ul li.usuario {
    margin-top: 95px;
}
.SideBarAgente ul li.usuario2 {
    background: rgb(5, 161, 233); /* Alterado para a cor azul céu */

}

