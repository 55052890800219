@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Poppins;
}

.tituloPagina {
    font-family: Poppins;
    color: white;
    margin-bottom: 20px;
}

.tabela {
    width: 80%;
    max-width: 1000px;
    border-collapse: collapse;
    margin: 30px auto 10px;
    color: white;
    margin-top: -17px;
}

.tabela th, .tabela td {
    border: 1px solid #ddd;
    padding: 7px;
    text-align: left;
    font-size: 11px;
}

.tabela th {
    background-color: rgb(1, 196, 255);
    color: white;
}

.tabela tr:hover {
    background-color: #ddd;
    color: black;
}

.containerAdminNavBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.tituloPagina {
    font-family: Poppins;
    color: white;
    margin-bottom: 20px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end; /* Alterado para flex-end para posicionar à direita */
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50px;
    margin-top: -30px;
   
   
}
.conteudo {
    display: flex;
    width: 23vw;
    margin-left: 70px;
    margin-top: 50px;
}

.conteudo_edit h1 {
    margin-top: 1.5em;
    margin-left: 30%;
    margin-bottom: 2em;
    color: #fff;
    text-transform: uppercase;

}
.conteudo_edit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.button:hover {
    background-color: rgb(5, 161, 233);
}


.vermelho {
    background-color: red;
    color: white;
  }
  
  .vermelho:hover {
    background-color: orange; /* Change this color to your desired hover effect */
  }
  
  .botaoRover {
    margin-right: 4px;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

