@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: Poppins;
   
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.content_container {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 70%; /* Ajusta a largura total da seção de conteúdo */
    height: 100%; /* Altura da seção de conteúdo igual à altura da viewport */
    
}
.image_section {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Adiciona margem entre a imagem e o texto */
}
.image {
    width: 300px; /* Ajusta o tamanho da imagem conforme necessário */
}
.image_content {
    color: #fff;
    text-align: left; /* Alinha o texto à esquerda */
}
.form_section {
    width: 50%; /* Largura do formulário */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.4px solid #fff;
    box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    padding: 21px;
    margin: 80px;
   
}
.form_section input {
    width: 100%;
    margin-bottom: 10px;
    padding: 0.4em;
}
.form_section hr {
    width: 70%;
    margin: 10px;
    background-color: green;
}
.form_section h1 {
    color: white;
    margin-top: .6em;
    font-size: 24px;
}
