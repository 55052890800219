@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
        font-family: Poppins;
}
.container {
    width: 100%;
    height: 100vh;
    margin-left: 15%;
}
.conteudo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50%;
    width: 100%;
}
.container h1 {
  margin-top: 1.5em;
  margin-left: 20%;
  margin-bottom: 2em;
  color: #fff;
  text-transform: uppercase;
}




.formularioEdicao {
  max-width: 400px; /* Reduzi a largura máxima do formulário */
  margin: 0 auto;
  padding: 15px; /* Reduzi o preenchimento interno do formulário */
  color: #fff;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgb(1, 196, 255);
  display: flex;
  margin-left: 250px;
  margin-top: 60px;
}

.formularioEdicao label {
  margin-bottom: 8px;
  font-weight: bold;
  margin-left: 30px;
}

.formularioEdicao input[type="text"],
.formularioEdicao select {
  width: calc(100% - 16px); /* Ajustei a largura para considerar a borda */
  padding: 8px;
  margin-bottom: 12px; /* Reduzi a margem inferior */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.formularioEdicao button {
  background-color: blue;
  color: white;
  border: none;
  padding: 15px 25px; /* Reduzi o padding do botão */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 94px;
  margin-top: 21px;
 
}

.botoesContainer {
  display: flex;
  gap: 5px; /* Ajuste o espaçamento entre os botões conforme necessário */
}

.formularioEdicao button:hover {
  background-color: #0056b3;
}


.containerAdminNavBar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


/* Add these styles to your existing CSS */

/* Styles for label elements */
.customSelectLabel {
  color: white;
  margin-bottom: 0.5em; /* Adjust the margin-bottom as needed */
  display: block; /* Ensures labels are on a new line */
}

/* Adjusted styles for Select elements */
.customSelect {
  width: 295px;
  color: black;
  margin-top: 0.5em; /* Adjusted margin-top */
  margin-left: 26px;
}

.customSelect1 {
  width: 400px;
}

/* Additional style for conteudo */
.conteudo {
  display: flex;
  flex-direction: column; /* Ensure elements are stacked vertically */
  justify-content: center;
  align-items: center;
  gap: 2em; /* Adjusted gap between elements */
}

/* Add these styles to your existing CSS */

/* Additional style for conteudo input */
.conteudo input {
  width: 300px;
  height: 2em; /* Adjusted height */
  margin-top: 0.5em;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
  padding: 8px; /* Adjusted padding */
}

/* Additional style for conteudo button */
.conteudo button {
  margin-top: 1em;
  width: 180px;
  height: 2.5em; /* Adjusted height */
  border: none;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  background-color: rgb(5, 161, 233);
}


