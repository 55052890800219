@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');


.card {
    border: 1px solid #2c3e50; /* Set border color to a darker shade */
    padding: 20px; /* Decrease padding for a smaller card */
    border-radius: 8px; /* Decrease border-radius for a less rounded appearance */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust box-shadow for a lighter lifted effect */
    margin: 0 auto; /* Center the card horizontally */
    width: 80%; /* Set a specific width for the card */
    max-width: 400px; /* Set a maximum width for responsiveness */
    background-color: #34495e; /* Set background color to a dark blue */
    color: #ffffff; /* Set text color to white */
}

.card strong {
    color: #ecf0f1; /* Set strong text color to a lighter shade of white */
}

.card button {
    margin-top: 15px; /* Increase margin-top for more space above buttons */
    padding: 10px 20px; /* Adjust padding for larger buttons */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Add color transition for a smooth effect */
    background-color: blue; /* Set button background color to a blue shade */
    color: #ffffff; /* Set button text color to white */
}

.card button:hover {
    background-color:rgb(5, 161, 233);  /* Darker shade on hover for a button effect */
}
.card .editButton {
    background-color: blue; /* Set button background color to a blue shade for "Editar" */
  }
  
  .card .deleteButton {
    margin-left: 10px;
    background-color: red; /* Set button background color to a red shade for "Excluir" */
  }
  .card .deleteButton:hover {
    background-color: orange; /* Change this color to your desired hover effect */
  }
