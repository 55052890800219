@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

.content_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
    max-width: 400px;
}

.content_form input {
    height: 10em; /* Aumente a altura do input */
    width:121%; /* Ajuste a largura do input para 100% */
    margin: 15px 0;
    border-radius: 6px;
    border: 1px solid grey;
    padding: 12px; /* Aumente o padding para dar mais espaço interno */
    font-size: 16px; /* Ajuste o tamanho da fonte se necessário */
}

.content_form input::placeholder {
    padding-left: 0.4em;
}

.content_form p {
    font-size: small;
    margin-top: 0.4em;
    color: #fff;
}
.content_formbutton {
    height: 5em; /* Aumente a altura do botão para 5em */
    width: 100%; /* Ajuste a largura do botão para 100% */
    cursor: pointer;
    border: none;
    border-radius: 6px;
    background-color: rgb(1, 196, 255);
    color: #fff;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    font-size: 10px; /* Ajuste o tamanho da fonte para 20px */
    margin-left: 60px;
}


.content_formbutton:hover {
    background-color: rgb(17, 0, 80);
}
