@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Oswald:wght@700&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

body {
    background-color: #101728; /* Set the background color here */
    font-family: Poppins;
    width: 100%;
    height: 100vh;
}

.sessao1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.conteudo1 {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.conteudo_titulo {
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace, sans-serifS;
    font-size: 54px;
    color: #fff;
}
.conteudo_titulo span {
    color: rgb(5, 161, 233);
    font-weight: bold;
}
.conteudo_subTitulo {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}
.btn {
    background-color: rgb(1, 196, 255);
    width: 180px;
    height: 60px;
    border: none;
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}
.btn:hover {
    background-color: rgb(17, 0, 80);
}
.container_pgn{
    width: 100%;
    height: 100vh;
    margin-left: 15%;
}

.conteudo_edit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.image2 {
    height: 400px;
    margin-top: 10%;
    margin-bottom: 10%;
    border-radius: 10%; /* Isso ajuda a garantir que a imagem seja exibida corretamente em uma forma circular */
}
.conteudo_secundario {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.tittle_secundario{
    padding-top: 1em;
    border-top: 0.4px solid #fff;
    margin-bottom: 2em;
}
.conteudo_texto{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.conteudo_texto strong{
    display: flex;
    justify-content: center;
    margin-bottom: 2.5em;
}

.texto {
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    border-radius: 13px;
    background-color: rgb(1, 196, 255);
    color: #fff;
    width: 14em;
    height: 25em;
    padding: 1.5em;
    margin: 1em;
}
.texto:hover {
    background-color: rgb(17, 0, 80);
    transition: 1s;
}
.icon {
    margin-right: 0.5em; /* Ajusta o espaçamento entre o ícone e o texto */
    vertical-align: middle;
  }


  @import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  body {
      background-color: #161616;
      font-family: Poppins;
  }
  .container {
      border-top: 0.4px solid #fff;
      width: 100vw;
      height: 100%;
  }
  .container_pgn{
      width: 100%;
      height: 100vh;
      margin-left: 15%;
  }
  .conteudo_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
  }
  .conteudo_edit h1 {
      margin-top: 1.5em;
      margin-left: 10%;
      margin-bottom: 2em;
      color: #fff;
      text-transform: uppercase;
  }
  .formGroup {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      margin-left: 17%;
      width: 70vw;
      text-align: justify;
  
  }  
  .formGroup label{
      width: 120px;
      color: white;
  
  } 
  
    
  .tittle_text {
      text-align: left;
      margin-left: 1em;
      margin-bottom: 2em;
      color: #fff;
  }
  .tittle_text p {
      width: 95vw;
      font-size: medium;
      text-align: left;
      margin-left: 2.2em;
      color: #f1f1f1;
  }
  .textoSobre {
      color: #fff;
      margin: 80px 10px 30px;
  }
  
  .container_pgn{
      width: 100vw;
      height: 100vh;
  }
  .conteudo_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 10%;
      color: #fff;
  }
  .conteudo_edit h1 {
      margin-top: 1.5em;
      margin-left: 10%;
      margin-bottom: 2em;
      color: #fff;
      text-transform: uppercase;
  }
  .formGroup {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      margin-left: 17%;
      width: 70vw;
  } 
  .formGroup label {
      width: 120px;
      color: white;
  }  
  
  