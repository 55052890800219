@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: Poppins;
}
.container {
    border-top: 0.4px solid #fff;
    width: 100vw;
    height: 100%;
}
.container_pgn{
    width: 100%;
    height: 100vh;
    margin-left: 15%;
}
.image2 {
    width: 600px;
    border: 2px solid #fff;
    
}
.conteudo_edit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}
.conteudo_edit h1 {
    margin-top: 1.5em;
    margin-left: 10%;
    margin-bottom: 2em;
    color: #fff;
    text-transform: uppercase;
}
.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 17%;
    width: 70vw;
    text-align: justify;

}  
.formGroup label{
    width: 120px;
    color: white;

} 

  
.tittle_text {
    text-align: left;
    margin-left: 1em;
    margin-bottom: 2em;
    color: #fff;
}
.tittle_text p {
    width: 95vw;
    font-size: medium;
    text-align: left;
    margin-left: 2.2em;
    color: #f1f1f1;
}
.textoSobre {
    color: #fff;
    margin: 80px 10px 30px;
}

.container_pgn{
    width: 100vw;
    height: 100vh;
}
.conteudo_edit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 10%;
    color: #fff;
}
.conteudo_edit h1 {
    margin-top: 1.5em;
    margin-left: 10%;
    margin-bottom: 2em;
    color: #fff;
    text-transform: uppercase;
}
.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 17%;
    width: 70vw;
} 
.formGroup label {
    width: 120px;
    color: white;
}  

