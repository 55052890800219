@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

.componenteBtn {
  text-align: center;
}

.btn_Cadastro {
  background-color: rgb(1, 196, 255);
  width: 180px;
  height: 60px;
  border: none;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Adicione uma transição para suavizar a mudança de cor de fundo */
}

.btn_Cadastro:hover {
  background-color: rgb(17, 0, 80);
}
