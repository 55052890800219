@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

.container {
    width: 100%;
    height: 100vh;
}



.container2 h1 {
  margin-top: -10%;
  margin-left: 35%;
  margin-bottom: 1.5em;
  color: #fff;
  text-transform: uppercase;
  

}

.conteudo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  width: 21vw;
  margin-left: 12%;
  margin-top: 45px;
}
.container button{
    margin-bottom: 10px;
}

.conteudo input{
    width: 10px;
    height: 3em;
    margin-top: 1.0em;
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    padding: 10px;
}  
  .tabela {
    width: 80%;
    max-width: 1000px;
    border-collapse: collapse;
    margin: 26px auto 10px;/* Ajuste a margem inferior conforme necessário */
    color: white;
    margin-left: 160px;
  }
  
  
  .tabela th, .tabela td {
    border: 1px solid #ddd;
    padding: 7px;
    text-align: left;
    font-size: 11px;
  }
  
  .tabela th {
    background-color: rgb(1, 196, 255);
    color: white;
  }
  
  .tabela tr:hover {
    background-color: #ddd;
    color: black;
  }
  
.titulo {
  font-size: 30px;
  color: white;
  margin-top: 60px; /* ou a quantidade desejada de margem superior */
  margin-left: 470px;
  
}

  .azul {
    background-color: blue;
    color: white;
  }
  
  .azul:hover {
    background-color: rgb(5, 161, 233);
  }
  
  .vermelho {
    background-color: red;
    color: white;
  }
  
  .vermelho:hover {
    background-color: orange;
  }
  
  .botaoRover {
    margin-right: 4px;
    color: white;
    border: none;
    padding: 8px 16px; /* Ajuste o valor do padding conforme necessário */
    border-radius: 4px;
    cursor: pointer;
    display: flex;

  }
  
 


.containerAdminNavBar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


/* Estilo para o botão Cadastrar */
button[type="submit"] {
  background-color:blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}



button[type="submit"]:hover {
  background-color: rgb(5, 161, 233);
}
.formularioEdicao {
  max-width: 400px; /* Reduzi a largura máxima do formulário */
  margin: 0 auto;
  padding: 15px; /* Reduzi o preenchimento interno do formulário */
  color: #fff;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgb(1, 196, 255);
}

.formularioEdicao label {
  margin-bottom: 8px;
  font-weight: bold;
}

.formularioEdicao input[type="text"],
.formularioEdicao select {
  width: calc(100% - 16px); /* Ajustei a largura para considerar a borda */
  padding: 8px;
  margin-bottom: 12px; /* Reduzi a margem inferior */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.formularioEdicao button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px; /* Reduzi o padding do botão */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 1px;
}

.botoesContainer {
  display: flex;
  gap: 5px; /* Ajuste o espaçamento entre os botões conforme necessário */
}

.formularioEdicao button:hover {
  background-color: #0056b3;
}

