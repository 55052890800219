@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
        font-family: Poppins;
}
.container {
    width: 100%;
    height: 100vh;
}
.conteudo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50%;
    width: 80vw;
}
.container h1 {
    margin-top: 1.5em;
    margin-left: 40%;
    color: #fff;
    text-transform: uppercase;
}
.conteudo input{
    width: 300px;
    height: 3em;
    margin-top: 2.5em;
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    padding: 10px;
}
.conteudo button {
    margin-top: 2.9em;
    width: 180px;
    height: 3em;
    border: none;
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    border-radius: 13px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background-color: green;
}

  .icones {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 30px;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    margin-left: 2em;
  }
  .editForm {
    width: 50%;
    max-width: 80vw;
    margin: 0 auto;
    margin-top: 30px;
  }
.editForm button {
  margin-top: 1em;
  border-radius: 8px;
  box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
}

.containerAdminNavBar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.formGroup label {
  width: 120px;
  color: white;
}

.formGroup input, select{
  flex: 1;
  height: 38px; 
  margin-left: 0;
  margin-right: 100px;
  font-family: inherit;
  font-size: 14px; 
  padding: 8px 12px; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  margin-top: 15px;
}
.formGroup textarea{
  flex: 1;
  height: 80px; 
  margin-left: 0;
  margin-right: 100px;
  margin-top: 10px;
  font-family: inherit;
  font-size: 14px; 
  padding: 8px 12px; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
}

.formGroup input:focus {
  outline: none; 
  box-shadow: 0 0 0 2px #3921a5; 
}
.formGroup select:focus {
  outline: none; 
  box-shadow: 0 0 0 2px #3921a5; 
}
.formGroup textarea:focus {
  outline: none; 
  box-shadow: 0 0 0 2px #3921a5; 
}.editForm button {
  width: 15em;
  height: 50px;
  box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 8px;
  background-color: rgb(1, 196, 255);
  color: #fff;
  margin-left: 40%;
  margin-top: 2em;
  transition: all 0.3s ease; /* Adiciona transição para a mudança suave */
}

.editForm button:hover {
  box-shadow: 6px 6px 6px rgba(17, 0, 80); /* Adiciona uma sombra mais forte no hover */
}

.mensagemSucesso {
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

