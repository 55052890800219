@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
        font-family: Poppins;

}

.tituloPagina {
    font-family: Poppins;
    color: white;
}

.container {
    width: 100%;
    height: 100vh;
}
.container h1 {
  margin-top: 1.5em;
  margin-left: 45%;
  margin-bottom: 1.5em;
  color: #fff;
  text-transform: uppercase;

}

.tabela {
  width: 80%;
  max-width: 1000px;
  border-collapse: collapse;
  margin: 50px 0 20px 190px; /* Ajuste a margem inferior conforme necessário */
  color: white;
}

.tabela th, .tabela td {
  border: 1px solid #ddd;
  padding: 7px; /* Ajuste o espaçamento interno das células */
  text-align: left;
  font-size: 11px;
}

.tabela th {
  background-color: rgb(1, 196, 255);
  color: white;
}

.tabela tr:hover {
  background-color: #ddd;
  color: black;

}
.containerAdminNavBar{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* Adicione estas regras ao seu arquivo de estilo CSS */
.paragraphStyle {
  font-size: 16px; /* Tamanho da fonte */
  color: white; /* Cor do texto */
  margin-bottom: 10px; /* Espaçamento inferior */
  /* Adicione mais estilos conforme necessário */
}
