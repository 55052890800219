@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');
label {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: white;
}

label input {
  margin-right: 8px;
}

label:hover {
  cursor: pointer;
}

/* Adicione estilos para destacar o texto dos termos e condições */
.terms-text {
  font-size: 14px;
  color: #777;
  margin-top: 8px;
}

/* Adicione estilos para realçar o texto dos termos e condições quando o checkbox estiver marcado */
label input:checked + .terms-text {
  color: #3921a5;
  font-weight: bold;
}

/* Adicione estilos para o foco no checkbox */
label input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3921a5;
}


.content_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content_form input::placeholder {
    padding-left: .4em;
  }


  .formGroup select, input{
    flex: 1;
    height: 38px; 
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
    font-family: inherit;
    font-size: 14px; 
    padding: 8px 12px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
  }
  
.content_form button {
  margin-right: .2em;
  margin-top: 1em;
  cursor: pointer;
}
.content_form input::placeholder {
    padding-left: .4em;
  }

  label {
    width: 270px;
    font-size: 14px; 
    color: white;
  }

  input:focus {
    outline: none; 
    box-shadow: 0 0 0 2px #3921a5; 
  }
   select:focus {
    outline: none; 
    box-shadow: 0 0 0 2px #3921a5; 
  }
  

  
