@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

body {
    font-family: Poppins, sans-serif;
}

.container {
    width: 100vw;
}

.cabecalho {
    background-color: #000000; /* Strong blue background */
    color: #fff; /* White text color */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 7px;
    gap: 30%;
}

.image {
    height: 60px;
}

.navbar  {
    display: flex;
    gap: 32px;
    font-weight: 400;
    color: #fff; /* White text color */
}

.item2 {
    margin-left: 3em;
}
