@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

.tituloPagina {
  text-align: center; /* Centraliza o texto */
  margin-top: 20px; /* Adapte conforme necessário para ajustar a distância do topo */
  color: white; /* Cor do texto */
  font-size: 30px; /* Tamanho da fonte */
  font-weight: bold; /* Peso da fonte (negrito) */
}

.container {
  width: 100%;
  height: 100vh;
}
.conteudo {
display: flex;
width: 21vw;
margin-left: 181px;
margin-bottom: 3%;
}

.container h1 {
  margin-top: 1.5em;
  margin-left: 37%;
  margin-bottom: 1.5em;
  color: #fff;
  text-transform: uppercase;

}
  
.tabela {
  width: 80%;
  max-width: 1000px;
  border-collapse: collapse;
  margin: -17px 0 20px 180px; /* Ajuste a margem inferior conforme necessário */
  color: white;
}

.azul {
background-color:  blue;
color: white;
}

.azul:hover {
background-color:rgb(5, 161, 233); /* Change this color to your desired hover effect */
}

.vermelho {
background-color: red;
color: white;
}

.vermelho:hover {
background-color: orange; /* Change this color to your desired hover effect */
}
.conteudo input{
  width: 300px;
  height: 3em;
  margin-top: 2.0em;
  box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
  padding: 10px;
}
.botaoRover {
margin-right: 4px;
color: white;
border: none;
padding: 8px 16px;
border-radius: 4px;
cursor: pointer;
display: flex;
}
.botoesContainer {
  display: flex;
  gap: 5px; /* Ajuste o espaçamento entre os botões conforme necessário */
}

.tabela th, .tabela td {
border: 1px solid #ddd;
padding: 7px; /* Ajuste o espaçamento interno das células */
text-align: left;
font-size: 11px;
}
.botaoAcao {
display: flex;
gap: 5px; /* Ajuste o espaçamento entre os botões conforme necessário */
}
.tabela th {
background-color: rgb(1, 196, 255);
color: white;
}

.tabela tr:hover {
background-color: #ddd;
color: black;

} .editForm {
  width: 50%;
  max-width: 80vw;
  margin: 0 auto;
  margin-top: 30px;
}
.editForm button {
margin-top: 1em;
border-radius: 8px;
box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
}

.containerAdminNavBar{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
}
.formGroup {
display: flex;
align-items: center;
margin-bottom: 8px;
}

.formGroup label {
width: 120px;
color: white;
margin-right: 40px;
}

.formGroup input, select{
flex: 1;
height: 38px; 
margin-left: 0;
margin-right: 100px;
font-family: inherit;
font-size: 14px; 
padding: 8px 12px; 
border: 1px solid #ccc; 
border-radius: 4px; 
}
.formGroup textarea{
flex: 1;
height: 80px; 
margin-left: 0;
margin-right: 100px;
margin-top: 10px;
font-family: inherit;
font-size: 14px; 
padding: 8px 12px; 
border: 1px solid #ccc; 
border-radius: 4px; 
}

.formGroup input:focus {
outline: none; 
box-shadow: 0 0 0 2px #3921a5; 
}
.formGroup select:focus {
outline: none; 
box-shadow: 0 0 0 2px #3921a5; 
}
.formGroup textarea:focus {
outline: none; 
box-shadow: 0 0 0 2px #3921a5; 
}.editForm button {
  width: 15em;
  height: 50px;
  box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 8px;
  background-color:rgb(1, 196, 255);
  color: #fff;
  margin-left: 40%;
  margin-top: 2em;
}
