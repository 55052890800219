@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');

.container {
  width: 100%;
  height: 100%;
  margin: 100px 0 100px 0;

}


.formularioEdicao {
  max-width: 400px; /* Reduzi a largura máxima do formulário */
  margin: 0 auto;
  padding: 15px; /* Reduzi o preenchimento interno do formulário */
  color: #fff;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgb(1, 196, 255);
}

.formularioEdicao label {
  margin-bottom: 8px;
  font-weight: bold;
}

.formularioEdicao input[type="text"],
.formularioEdicao select {
  width: calc(100% - 16px); /* Ajustei a largura para considerar a borda */
  padding: 8px;
  margin-bottom: 12px; /* Reduzi a margem inferior */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.formularioEdicao button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px; /* Reduzi o padding do botão */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.botoesContainer {
  display: flex;
  gap: 5px; /* Ajuste o espaçamento entre os botões conforme necessário */
}

.formularioEdicao button:hover {
  background-color: #0056b3;
}
.containerAdminNavBar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Estilo para os termos e condições LGPD */

/* Estilo para os termos e condições LGPD */
.lgpd {
  margin-bottom: 30px;

  
}

.lgpd label{
  color: black;
  font-size: 14px;
  
  
  
}
.lgpd input{
  margin-bottom: 14px;
}
.lgpd a {
color: rgb(175, 17, 17);  
}
/* Estilo para o botão Cadastrar */
button[type="submit"] {
  background-color:blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-left: 195px;
}



button[type="submit"]:hover {
  background-color: rgb(5, 161, 233);
}
.containerAdminNavBar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

