@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Oswald:wght@700&family=Poppins:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

body {
    background-color: #101728 !important;
    font-family: Poppins;
    width: 100%;
    height: 100vh;
}
.container{
    width: 100%;
    height: 100vh;
    font-family: Poppins;
}
.sessao1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.conteudo1 {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.conteudo_titulo {
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace, sans-serifS;
    font-size: 54px;
    color: #fff;
}
.conteudo_titulo span {
    color: rgb(5, 161, 233);
    font-weight: bold;
}
.conteudo_subTitulo {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}
.btn {
    background-color: rgb(1, 196, 255);
    width: 180px;
    height: 60px;
    border: none;
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}
.btn:hover {
    background-color: rgb(17, 0, 80);
}

.image2 {
    height: 400px;
    margin-top: 10%;
    margin-bottom: 10%;
    border-radius: 10%; /* Isso ajuda a garantir que a imagem seja exibida corretamente em uma forma circular */
}
.conteudo_secundario {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.tittle_secundario{
    padding-top: 1em;
    border-top: 0.4px solid #fff;
    margin-bottom: 2em;
}
.conteudo_texto{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.conteudo_texto strong{
    display: flex;
    justify-content: center;
    margin-bottom: 2.5em;
}

.texto {
    box-shadow: 4px 5px 4px rgba(0,0,0,0.25);
    border-radius: 13px;
    background-color: rgb(1, 196, 255);
    color: #fff;
    width: 18em;
    height: 25em;
    padding: 1.5em;
    margin: 1em;
}
.texto:hover {
    background-color: rgb(17, 0, 80);
    transition: 1s;
}
.icon {
    margin-right: 0.5em; /* Ajusta o espaçamento entre o ícone e o texto */
    vertical-align: middle;
  }