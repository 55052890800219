@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Poppins:wght@500&family=Titillium+Web:ital,wght@1,600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: Poppins, sans-serif;
}

/* Estilo CSS para o conteúdo da página de login */
.conteudoLogin {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10%;
}

/* Estilo CSS para a seção do título */
.titulo {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.conteudo_titulo span {
    color: rgb(5, 161, 233);
    font-weight: bold;
}

.titulo h1 {
    font-size: 55px;
    margin-left: 20px;
    margin-top: 2.5em;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    color: #fff;
}

.titulo p {
    text-align: left;
    font-size: 20px;
    width: 50vw;
    color: #fff;
}

.formulario {
    width: 80%;
    max-width: 300px; /* Reduzi a largura máxima do formulário */
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 23px;
    border: 0.4px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}




.formulario h3 {
    color: #fff;
    margin: 0.5em;
    font-size: 16px; /* Reduzi o tamanho da fonte */
}

.formulario div {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
}

.formulario div input[type="radio"] {
    margin-right: 5px;
    margin-bottom: 15px;
}

.formulario .botaoCadastro {
    width: 50%;
    max-width: 200px;
    height: 35px; /* Reduzi a altura do botão */
    box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-transform: upper;
    border-radius: 10px;
    background-color: #fff;
    color:rgb(9, 159, 204);
    transition: background-color 0.3s ease;
    font-size: 12px; /* Reduzi o tamanho da fonte */
}
.formulario .botaoCadastro:hover {
    background-color: rgb(17, 0, 80);
    color: #fff;
}

.formulario button{
    color: #fff;
    width: 100%;
    max-width: 200px;
    height: 50px; /* Reduzi a altura do botão */
    box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 10px;
    background-color: rgb(1, 196, 255);
    transition: background-color 0.3s ease;
    font-size: 15px; /* Reduzi o tamanho da fonte */
    margin-left: 1px;
}


.formulario button:hover {
    background-color: rgb(17, 0, 80);
}

/* Estilo CSS para os ícones dos radio buttons (se estiver usando ícones) */
.formulario div svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.titulo h1,
.titulo p,
.formulario h3,
.formulario button,
.formulario div svg,
ul li { 
    color: #fff; /* Define a cor do texto como branco para esses elementos */
}

.conteudo_titulo {
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace, sans-serifS;
    font-size: 50px;
    color: #fff;
}
.conteudo_titulo2 {
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace, sans-serifS;
    font-size: 10px;
    color: #fff;
}
.image2 {
    height: 300px;
    border-radius: 10%; /* Isso ajuda a garantir que a imagem seja exibida corretamente em uma forma circular */
}
.conteudo_subTitulo {
    font-size: 15px;
    color: #fff;
}
.conteudo_login {
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace, sans-serifS;
    font-size: 30px;
    color: #fff;
}